<template>
  <div>
    <carousel></carousel>
    <categorias></categorias>
    <destacados></destacados>
    <banners></banners>
    <newsletter></newsletter>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Marketplace',
  },
  components: {
    Carousel: () => import('./Carousel'),
    Categorias: () => import('./Categorias.vue'),
    Banners: () => import('./Banners'),
    Newsletter: () => import('./Newsletter'),
    Destacados: () => import('./Destacados'),
  }

}
</script>
